body.department.aarhus-bss {
  background-color: #F4F3F4;
}
body.department.aarhus-bss header .is-dropdown-submenu-parent:after {
  right: 1.2rem;
}
body.department.aarhus-bss > .row,
body.department.aarhus-bss #au_content {
  background: #fff;
}
body.department.aarhus-bss .row.pagenav {
  padding-left: 1.3636364rem;
  padding-right: 1.3636364rem;
}
body.department.aarhus-bss > .row:not(.breadcrumb) {
  padding-left: 1.3636364rem;
  padding-right: 1.3636364rem;
}
@media screen and (max-width: 39.9375em) {
  body.department.aarhus-bss > .row:not(.breadcrumb) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
body.department.aarhus-bss header {
  font-family: "AUPassataRegular";
}
@media screen and (max-width: 39.9375em) {
  body.department.aarhus-bss header .logo {
    padding-bottom: 0 !important;
  }
  body.department.aarhus-bss header .logo > h2 {
    margin-left: 0;
  }
}
body.department.aarhus-bss header .section-header {
  height: auto;
}
body.department.aarhus-bss header .section-header .columns {
  background-color: #002546;
  padding: 1.3636364rem;
}
body.department.aarhus-bss header .header-menu .columns {
  background-color: #002546;
  border-bottom: none;
}
body.department.aarhus-bss header .header-menu:not(.open) .menu > li:hover {
  background-color: #003d73;
}
body.department.aarhus-bss header .header-menu:not(.open) .menu > li li {
  background-color: #fff;
}
body.department.aarhus-bss header .header-menu:not(.open) .menu > li li:hover {
  background-color: #003d73;
}
body.department.aarhus-bss header .header-menu:not(.open) .menu > li li:hover a {
  color: #fff;
}
body.department.aarhus-bss header .header-menu .title-bar {
  padding-top: 0;
}
body.department.aarhus-bss header .header-menu .title-bar .menu-icon {
  margin-left: 0;
  margin-right: 0;
}
body.department.aarhus-bss header .header-menu .mobile-nav button::after {
  background-color: #fff;
  box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
}
body.department.aarhus-bss header .search {
  border-left: none;
  width: auto;
  right: 1.3636364rem;
  padding-top: 1.3636364rem;
}
body.department.aarhus-bss header .search input[type=search] {
  margin-bottom: 0;
  background: #002a50 !important;
}
body.department.aarhus-bss header .search input[type=search]:focus {
  background: #fff !important;
}
body.department.aarhus-bss header .search .au_searchform::before {
  right: 1.3636364rem;
  color: #bfbfbf;
}
@media screen and (max-width: 39.9375em) {
  body.department.aarhus-bss header .search {
    padding: 0;
  }
}
body.department.aarhus-bss header .language-selector {
  top: 24px;
  right: 210px;
}
body.department.aarhus-bss header .language-selector p {
  display: inline-block;
  margin-bottom: 0;
}
body.department.aarhus-bss header .language-selector p a {
  display: initial;
  position: initial;
  top: 0;
}
@media screen and (max-width: 39.9375em) {
  body.department.aarhus-bss header .language-selector {
    top: 60px;
    right: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important;
  }
  body.department.aarhus-bss header .language-selector p:first-child {
    display: none;
  }
}
body.department.aarhus-bss header .logo h2 {
  background: url("https://cdn.au.dk/2015/au/graphics/bss/bsslogo-bg.png") center left no-repeat;
  height: 70px;
  background-size: 70px 70px;
  text-transform: uppercase;
  font-family: "AUPassataRegular";
}
body.department.aarhus-bss header .logo h2 strong {
  font-family: "AUPassataBold";
}
body.department.aarhus-bss header .logo h2 a {
  display: block;
  width: 50px;
  height: 100%;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
  body.department.aarhus-bss header .logo h2 {
    background-image: url("https://cdn.au.dk/2015/au/graphics/bss/bsslogo-bgx2.png");
  }
}
body.department.aarhus-bss header .aubss {
  position: absolute;
  color: #fff;
  font-size: 15px;
  width: 67px;
  text-align: right;
  top: 47px;
  line-height: 16px;
}
body.department.aarhus-bss header .aarhusbss__logo {
  position: absolute;
  font-size: 15px;
  float: left;
  display: block;
  color: #fff;
}
body.department.aarhus-bss header .aarhusbss__logo--l4 {
  top: 16px;
  left: 104px;
}
body.department.aarhus-bss header .aarhusbss__logo--l3 {
  top: 33px;
  left: 104px;
}
body.department.aarhus-bss header .aarhusbss__logo--l2 {
  top: 45px;
  left: 94px;
  white-space: nowrap;
}
body.department.aarhus-bss header .aarhusbss__logo--l1 {
  top: 61px;
  left: 94px;
}
@media screen and (max-width: 39.9375em) {
  body.department.aarhus-bss header .aarhusbss__logo--l1,
body.department.aarhus-bss header .aarhusbss__logo--l2,
body.department.aarhus-bss header .aarhusbss__logo--l3,
body.department.aarhus-bss header .aarhusbss__logo--l4 {
    display: none;
  }
}
body.department.aarhus-bss header .is-dropdown-submenu {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
}
body.department.aarhus-bss header .is-dropdown-submenu-parent {
  border-right: 1px solid #004079;
}
body.department.aarhus-bss header .is-dropdown-submenu-parent li {
  border-right: none;
}
body.department.aarhus-bss header .is-dropdown-submenu-parent a {
  color: #fff;
}
body.department.aarhus-bss header .is-dropdown-submenu-parent .is-dropdown-submenu-item a {
  color: #002546;
}
body.department.aarhus-bss header .is-dropdown-submenu-parent::after {
  color: rgba(255, 255, 255, 0.6);
}
body.department.aarhus-bss header .utility-links {
  right: 1.3636364rem !important;
  top: 70px;
  left: initial !important;
}
@media screen and (max-width: 39.9375em) {
  body.department.aarhus-bss header .utility-links {
    right: 0 !important;
    top: 0 !important;
  }
}
body.department.aarhus-bss header .utility-links ul.utility {
  column-count: initial;
  font-size: 1.1rem;
}
body.department.aarhus-bss header .utility-links ul.utility > li {
  display: inline-block;
  margin-left: 1.3636364rem;
  margin-right: auto !important;
}
@media screen and (max-width: 39.9375em) {
  body.department.aarhus-bss header .utility-links ul.utility > li {
    display: block;
    margin-left: 0;
  }
  body.department.aarhus-bss header .utility-links ul.utility > li > a {
    padding-left: 1.2rem;
  }
}
body.department.aarhus-bss header .utility-links ul.utility > li > a::after {
  content: "";
}
body.department.aarhus-bss header .utility-links ul.utility > li:last-child {
  display: none;
}
body.department.aarhus-bss header + .row {
  background-color: #fff;
}
body.department.aarhus-bss footer.global {
  margin-top: 0;
}
body.department.aarhus-bss footer.global .columns {
  background-color: #002546;
}
@media screen and (max-width: 39.9375em) {
  body.department.aarhus-bss footer.global .columns {
    padding-top: 1.3636364rem;
    padding-bottom: 1.3636364rem;
  }
}
body.department.aarhus-bss .footer.localfooter {
  background-color: #002546;
  color: #fff;
}
body.department.aarhus-bss .footer.localfooter .au_social_sharebox li {
  padding-left: 0;
}
body.department.aarhus-bss .footer.localfooter p a {
  color: #fff;
}
body.department.aarhus-bss.level0 .au_focus {
  margin-top: 0;
}
body.department.aarhus-bss.level0 .content .au_focus h1 {
  background: none;
  text-transform: none !important;
  font-family: AUPassataRegular;
  font-size: 40px;
  padding: 0;
}
body.department.aarhus-bss.level0 .au_focus p {
  background: none;
  padding: 0;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.au_alphabox-bss {
  border: 2px solid #ccc;
  background-image: url(//bss.au.dk/fileadmin/BSS/aarhus-bss-studerende4.jpg);
  padding: 1.3636364rem;
}
@media screen and (max-width: 39.9375em) {
  .au_alphabox-bss {
    margin-bottom: 1.3636364rem;
  }
}
.au_alphabox-bss > h3 {
  margin: 0;
}
.au_alphabox-bss .au_alphabox > .au_alphabox {
  margin-bottom: 0;
  background-color: transparent !important;
}
.au_alphabox-bss .au_alphabox > .au_alphabox > div {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.au_alphabox-bss .au_alphabox > .au_alphabox > div > h2 {
  display: none;
}
.au_alphabox-bss .au_alphabox > .au_alphabox > div > p {
  order: 2;
  color: #000;
  margin-top: 4.45455rem !important;
}
.au_alphabox-bss .au_alphabox > .au_alphabox > div > ul {
  order: 3;
}
.au_alphabox-bss .au_alphabox > .au_alphabox > div .react-autosuggest__container {
  order: 1;
}
.au_alphabox-bss .au_alphabox .au_alphabox_content {
  display: none;
}
.au_alphabox-bss .au_alphabox ul.quicklinks li {
  margin-bottom: 0;
  width: calc(50% - .5rem);
}
.au_alphabox-bss .au_alphabox ul.quicklinks li:nth-child(odd) {
  margin-right: 1rem;
}
.au_alphabox-bss .au_alphabox ul.quicklinks li a {
  text-decoration: none;
  text-transform: none;
  padding: 0.85em 1em;
  margin: 0 0 1rem 0;
  font-size: 1.55rem;
}
.au_alphabox-bss .au_alphabox input[type=text] {
  background-color: #fff;
  margin-bottom: 0 !important;
  border: 1px solid #cacaca;
}
.au_alphabox-bss .au_alphabox ::-webkit-input-placeholder {
  color: #cacaca;
}
.au_alphabox-bss .au_alphabox ::-moz-placeholder {
  color: #cacaca;
  opacity: 1;
}
.au_alphabox-bss .au_alphabox :-ms-input-placeholder {
  color: #cacaca;
}

.find-container-bss {
  background-image: url(//bss.au.dk/fileadmin/BSS/bss-bygning-lysblaa2.jpg);
  background-size: cover;
  border: 1px solid #ccc;
  padding: 1.3636364rem;
}
.find-container-bss .vcard {
  margin-bottom: 1.3636364rem;
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.find-container-bss .find-container + ul {
  margin: 3rem 0;
}

.au_alphabox-bss,
.find-container-bss {
  min-height: 425px;
}
.au_alphabox-bss ul.resetlist.links,
.find-container-bss ul.resetlist.links {
  margin-bottom: 0;
  display: flex;
}
.au_alphabox-bss ul.resetlist.links li,
.find-container-bss ul.resetlist.links li {
  width: calc(50% - .5rem);
}
.au_alphabox-bss ul.resetlist.links li:first-child,
.find-container-bss ul.resetlist.links li:first-child {
  margin-right: 0.5rem;
}
.au_alphabox-bss ul.resetlist.links li:last-child,
.find-container-bss ul.resetlist.links li:last-child {
  margin-left: 0.5rem;
}
.au_alphabox-bss ul.resetlist.links li a,
.find-container-bss ul.resetlist.links li a {
  margin-bottom: 0;
  display: block;
}
.au_alphabox-bss .large-centered,
.find-container-bss .large-centered {
  margin-left: auto;
  margin-right: auto;
  float: none;
}
@import '../settings';
$bss-color: #002546;
$padding-default: 1.3636364rem;

body.department.aarhus-bss {
  background-color: #F4F3F4;

  header .is-dropdown-submenu-parent:after {
    right: 1.2rem;
  }

  >.row,
  #au_content {
    background: #fff;
  }

  .row.pagenav {
    padding-left: $padding-default;
    padding-right: $padding-default;
  }

  >.row:not(.breadcrumb) {
    padding-left: $padding-default;
    padding-right: $padding-default;

    @include breakpoint(small only) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  header {
    font-family: "AUPassataRegular";

    @include breakpoint(small only) {
      .logo {
        padding-bottom: 0 !important;

        >h2 {
          margin-left: 0;
        }
      }
    }

    .section-header {
      height: auto;

      .columns {
        background-color: $bss-color;
        padding: $padding-default;
      }
    }

    .header-menu {
      .columns {
        background-color: $bss-color;
        border-bottom: none;
      }

      &:not(.open) .menu>li {
        &:hover {
          background-color: $secondary-color;
        }

        li {
          background-color: #fff;

          &:hover {
            background-color: $secondary-color;

            a {
              color: #fff;
            }
          }
        }
      }

      .title-bar {
        padding-top: 0;

        .menu-icon {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .mobile-nav {
        button {
          &::after {
            background-color: #fff;
            box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
          }
        }
      }
    }

    .search {
      border-left: none;
      width: auto;
      right: $padding-default;
      padding-top: $padding-default;

      input[type="search"] {
        margin-bottom: 0;
        background: lighten($color: $bss-color, $amount: 2%) !important;

        &:focus {
          background: #fff !important;
        }
      }

      .au_searchform {
        &::before {
          right: $padding-default;
          color: darken($color: #fff, $amount: 25%);
        }
      }

      @include breakpoint(small only) {
        padding: 0;
      }
    }

    .language-selector {
      top: 24px;
      right: 210px;

      p {
        display: inline-block;
        margin-bottom: 0;

        a {
          display: initial;
          position: initial;
          top: 0;
        }
      }

      @include breakpoint(small only) {
        top: 60px;
        right: 0 !important;
        padding-left: 0 !important;
        padding-top: 0 !important;

        p:first-child {
          display: none;
        }
      }
    }

    .logo h2 {
      background: url("https://cdn.au.dk/2015/au/graphics/bss/bsslogo-bg.png") center left no-repeat;
      height: 70px;
      background-size: 70px 70px;
      text-transform: uppercase;
      font-family: "AUPassataRegular";

      strong {
        font-family: "AUPassataBold";
      }

      a {
        display: block;
        width: 50px;
        height: 100%;
      }
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi) {
      .logo h2 {
        background-image: url("https://cdn.au.dk/2015/au/graphics/bss/bsslogo-bgx2.png");
      }
    }

    .aubss {
      position: absolute;
      color: #fff;
      font-size: 15px;
      width: 67px;
      text-align: right;
      top: 47px;
      line-height: 16px;
    }

    .aarhusbss__logo {
      position: absolute;
      font-size: 15px;
      float: left;
      display: block;
      color: #fff;
    }

    .aarhusbss__logo--l4 {
      top: 16px;
      left: 104px;
    }

    .aarhusbss__logo--l3 {
      top: 33px;
      left: 104px;
    }

    .aarhusbss__logo--l2 {
      top: 45px;
      left: 94px;
      white-space: nowrap;
    }

    .aarhusbss__logo--l1 {
      top: 61px;
      left: 94px;
    }

    @include breakpoint(small only) {

      .aarhusbss__logo--l1,
      .aarhusbss__logo--l2,
      .aarhusbss__logo--l3,
      .aarhusbss__logo--l4 {
        display: none;
      }
    }

    .is-dropdown-submenu {
      box-shadow: 0px 3px 5px rgba(0, 0, 0, .25);
    }

    .is-dropdown-submenu-parent {
      border-right: 1px solid lighten($bss-color, 10%);

      li {
        border-right: none;
      }

      a {
        color: #fff;
      }

      .is-dropdown-submenu-item {
        a {
          color: $bss-color;
        }
      }

      &::after {
        color: rgba(255, 255, 255, .6);
      }
    }

    .utility-links {
      right: $padding-default !important;
      top: 70px;
      left: initial !important;

      @include breakpoint(small only) {
        right: 0 !important;
        top: 0 !important;
      }

      ul.utility {
        column-count: initial;
        font-size: 1.1rem;

        >li {
          display: inline-block;
          margin-left: $padding-default;
          margin-right: auto !important;

          @include breakpoint(small only) {
            display: block;
            margin-left: 0;

            >a {
              padding-left: 1.2rem;
            }
          }

          >a {
            &::after {
              content: '';
            }
          }

          &:last-child {
            display: none;
          }
        }
      }
    }

    +.row {
      background-color: #fff;
    }
  }

  footer.global {
    margin-top: 0;

    .columns {
      background-color: $bss-color;

      @include breakpoint(small only) {
        padding-top: $padding-default;
        padding-bottom: $padding-default;
      }
    }
  }

  .footer.localfooter {
    background-color: $bss-color;
    color: #fff;

    .au_social_sharebox li {
      padding-left: 0;
    }

    p a {
      color: #fff;
    }
  }

  &.level0 {
    .au_focus {
      margin-top: 0;
    }

    .content .au_focus h1 {
      background: none;
      text-transform: none !important;
      font-family: AUPassataRegular;
      font-size: 40px;
      padding: 0;
    }

    .au_focus p {
      background: none;
      padding: 0;
    }
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.au_alphabox-bss {
  border: 2px solid #ccc;
  background-image: url(//bss.au.dk/fileadmin/BSS/aarhus-bss-studerende4.jpg);
  padding: $padding-default;

  @include breakpoint(small only) {
    margin-bottom: $padding-default;
  }

  >h3 {
    margin: 0;
  }

  .au_alphabox {
    >.au_alphabox {
      margin-bottom: 0;
      background-color: transparent !important;

      >div {
        display: flex;
        flex-direction: column;
        padding: 0;

        >h2 {
          display: none;
        }

        >p {
          order: 2;
          color: #000;
          margin-top: 4.45455rem !important;
        }

        >ul {
          order: 3;
        }

        .react-autosuggest__container {
          order: 1;
        }
      }
    }

    .au_alphabox_content {
      display: none;
    }

    ul.quicklinks li {
      margin-bottom: 0;
      width: calc(50% - .5rem);

      &:nth-child(odd) {
        margin-right: 1rem;
      }

      a {
        text-decoration: none;
        text-transform: none;
        padding: .85em 1em;
        margin: 0 0 1rem 0;
        font-size: 1.55rem;
      }
    }

    input[type="text"] {
      background-color: #fff;
      margin-bottom: 0 !important;
      border: 1px solid #cacaca;
    }

    ::-webkit-input-placeholder {
      color: #cacaca;
    }

    ::-moz-placeholder {
      color: #cacaca;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #cacaca;
    }
  }
}

.find-container-bss {
  background-image: url(//bss.au.dk/fileadmin/BSS/bss-bygning-lysblaa2.jpg);
  background-size: cover;
  border: 1px solid #ccc;
  padding: $padding-default;

  .vcard {
    margin-bottom: $padding-default;
    background-color: rgba(255, 255, 255, .4) !important;
  }

  .find-container {
    +ul {
      margin: 3rem 0;
    }
  }
}

.au_alphabox-bss,
.find-container-bss {
  min-height: 425px;

  ul.resetlist.links {
    margin-bottom: 0;
    display: flex;

    li {
      width: calc(50% - .5rem);

      &:first-child {
        margin-right: .5rem;
      }

      &:last-child {
        margin-left: .5rem;
      }

      a {
        margin-bottom: 0;
        display: block;
      }
    }
  }

  .large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}